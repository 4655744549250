import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getCookie, deleteCookie } from "../../../lib/helpers";
import axios from "axios";

export const fetchUserByToken = createAsyncThunk(
  "users/fetchByIdStatus",

  async (thunkAPI, { rejectWithValue }) => {
    let userToken = getCookie("userToken") || null;
    if (userToken) {
      try {
        const axiosHeaders = {
          headers: {
            Authorization: "Bearer ".concat(userToken),
          },
        };
        const curUser = await axios.get("/api/v5/current_user", axiosHeaders);
        return curUser;
      } catch (e) {
        if (!e.response) {
          throw e;
        }
        rejectWithValue(e.response.data);
      }
    } else {
      throw error;
    }
  },
);

export const checkInviteValidity = createAsyncThunk(
  "users/checkInviteValidity",

  async (inviteToken, { rejectWithValue }) => {
    if (inviteToken) {
      try {
        const isValid = await axios.post("/api/v5/verifications/email/verify", {
          invite_token: inviteToken,
        });
        return isValid;
      } catch (e) {
        if (!e.response) {
          throw e;
        }
        return e.response.data;
      }
    } else {
      throw error;
    }
  },
);

//Initial state

const initialState = {
  project: {},
  curPage: "Dashboard",
  curFormPage: 0,
  curFilter: "all",
  searchVal: "",
  cloudinary: {},
  user: {},
  loadingUser: "pending",
  partnerToast: {
    headline: "",
    subhead: "",
    additionalStyling: "",
  },
  imageList: [],
  tagsList: {},
  stripeUrl: "",
  warnings: [],
  status: "in_progress",
  submitModalDisplayed: false,
  steps: {},
  contactFormModal: false,
  isNext: {
    type: "",
    id: 0,
    step: "",
  },
  readyToSubmit: {
    locations: false,
    brands: false,
    finance: false,
  },
};

// Create reducers/actions

export const partnerSlice = createSlice({
  name: "partner",
  initialState,
  reducers: {
    setProject: (state, action) => {
      state.project = action.payload;
    },
    setCurPage: (state, action) => {
      state.curPage = action.payload;
    },
    setCurFormPage: (state, action) => {
      state.curFormPage = action.payload;
    },
    setCurFilter: (state, action) => {
      state.curFilter = action.payload;
    },
    setSearchVal: (state, action) => {
      state.searchVal = action.payload;
    },
    setCloudinary: (state, action) => {
      state.cloudinary = action.payload;
    },
    setImageList: (state, action) => {
      state.imageList = action.payload;
    },
    setTagsList: (state, action) => {
      state.tagsList = action.payload;
    },
    setPartnerToast: (state, action) => {
      state.partnerToast = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setStripeUrl: (state, action) => {
      state.stripeUrl = action.payload;
    },
    setWarnings: (state, action) => {
      state.warnings = action.payload;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    setSubmitModalDisplayed: (state, action) => {
      state.submitModalDisplayed = action.payload;
    },
    setReadyToSubmit: (state, action) => {
      state.readyToSubmit = action.payload;
    },
    setSteps: (state, action) => {
      state.steps = action.payload;
    },
    setIsNext: (state, action) => {
      state.isNext = action.payload;
    },
    setContactFormModal: (state, action) => {
      state.contactFormModal = action.payload;
    },
    logOutUser: (state) => {
      state.user = null;
      state.project = {};
      state.curFilter = "all";
      state.searchVal = "";
      state.imageList = [];
      state.tagsList = [];
      state.stripeUrl = "";
      state.warnings = [];
      state.status = "in_progress";
      state.curFormPage = 0;
      state.imageList = [];
      state.steps = {};
      state.contactFormModal = false;
      state.isNext = {
        type: "",
        id: 0,
        step: "",
      };
      state.readyToSubmit = {
        locations: false,
        brands: false,
        finance: false,
      };
      state.partnerToast = {
        headline: "",
        subhead: "",
        additionStyling: "",
      };
      state.submitModalDisplayed = false;
      state.contactFormModal = false;
      deleteCookie();
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserByToken.fulfilled, (state, action) => {
        // Add user to the state array
        if (action.payload != null || undefined) {
          state.user = action.payload.data;
        }
        state.loadingUser = "idle";
      })
      .addCase(fetchUserByToken.pending, (state, action) => {
        if (state.loadingUser === "idle") {
          state.loadingUser = "pending";
        }
      })
      .addCase(fetchUserByToken.rejected, (state, action) => {
        if (state.loadingUser === "pending") {
          state.loadingUser = "idle";
          state.newUser = true;
        }
      });
  },
});

// Export actions

export const {
  setProject,
  setCurPage,
  setCurFormPage,
  setCurFilter,
  setSearchVal,
  setCloudinary,
  setImageList,
  setTagsList,
  setPartnerToast,
  setStripeUrl,
  setWarnings,
  setUser,
  setStatus,
  setReadyToSubmit,
  setSubmitModalDisplayed,
  setSteps,
  setIsNext,
  setContactFormModal,
  logOutUser,
} = partnerSlice.actions;

export default partnerSlice.reducer;

// Define a service using a base URL and expected endpoints
export const partnerApi = createApi({
  reducerPath: "partnerApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "/",
    prepareHeaders: (headers, { getState }) => {
      let userToken = getCookie("userToken") || null;
      if (!userToken) {
        return "Missing user token";
      } else {
        // If we have a token set in state, let's assume that we should be passing it.
        headers.set("Authorization", `Bearer ${userToken}`);
        return headers;
      }
    },
  }),
  endpoints: (builder) => ({
    getLocationsData: builder.query({
      query: (data) => `${data.slug}/locations.json`,
    }),
    getFinancesData: builder.query({
      query: (data) => `${data.slug}/partner_stripe_accounts.json`,
    }),
    getTeamMembersData: builder.query({
      query: (string) => `${string}/team_members.json`,
    }),
    getPermissionsData: builder.query({
      query: (data) => `${data.slug}/permissions.json`,
    }),
    getLocationsDataDisconnected: builder.query({
      query: (string) => `${string}.json?connected_status=disconnected`,
    }),
    getTagsData: builder.query({
      query: () => `/brand_tags.json`,
    }),
    getBrandImagesData: builder.query({
      query: (projectName) => `${projectName}/brand_images.json`,
    }),
    getDashboardData: builder.query({
      query: (projectName) => `${projectName}.json?request_for=all`,
    }),
    postStripeLink: builder.mutation({
      query: (projectName) => {
        return {
          url: `${projectName}/oauth_links`,
          method: "POST",
        };
      },
    }),
    deleteStripeAccount: builder.mutation({
      query: (data) => {
        const projectName = data.slug;
        const id = Number(data.id);
        return {
          url: `${projectName}/partner_stripe_accounts/${id}`,
          method: "DELETE",
        };
      },
    }),
    postResendTeamMember: builder.mutation({
      query: (data) => {
        let slug = data.team_members.slug;
        delete data.team_members.slug;
        let payload = data;
        return {
          url: `${slug}/team_members.json`,
          method: "POST",
          body: payload,
        };
      },
    }),
    postLogosData: builder.mutation({
      query: (data) => {
        let slug = data.slug;
        let brandId = data.brandId;
        delete data.slug;
        delete data.brandId;
        let payload = data;
        return {
          url: `/${slug}/brands/${brandId}/logos`,
          method: "POST",
          body: payload,
        };
      },
    }),
    putSelectedImagesData: builder.mutation({
      query: (data) => {
        let slug = data.slug;
        let brandId = data.brandId;
        let payload = {
          selected_brand_image_ids: data.setup_media_library,
        };
        return {
          url: `/${slug}/brands/${brandId}/brand_images`,
          method: "PUT",
          body: payload,
        };
      },
    }),
    putEditTeamMember: builder.mutation({
      query: (data) => {
        let slug = data.team_members.slug;
        let id = data.team_members.member_id;
        delete data.team_members.slug;
        delete data.team_members.member_id;
        delete data.team_members.additional_permissions;
        delete data.team_members.partner_project_id;
        let payload = data;
        return {
          url: `${slug}/team_members/${id}`,
          method: "PUT",
          body: payload,
        };
      },
    }),
    deleteTeamMember: builder.mutation({
      query: (data) => {
        let slug = data.team_members.slug;
        let id = data.team_members.member_id;
        delete data.team_members.slug;
        delete data.team_members.member_id;
        let payload = data;
        return {
          url: `${slug}/team_members/${id}`,
          method: "DELETE",
          body: payload,
        };
      },
    }),
    postCheckSnippet: builder.mutation({
      query: (data) => {
        let payload = {
          partner_brand_id: Number(data.id),
        };
        return {
          url: `/${data.project}/popup_js_verifications`,
          method: "POST",
          body: payload,
        };
      },
    }),
    getBrandsData: builder.query({
      query: (data) => `${data.slug}/brands.json`,
    }),
    putStatus: builder.mutation({
      query: (data) => {
        let slug = data.slug;
        let payload = {
          project: {},
        };
        if (data.status) {
          payload.project.status = data.status;
        }
        if (data.submit_modal_displayed_at) {
          payload.project.submit_modal_displayed_at =
            data.submit_modal_displayed_at;
        }
        return {
          url: `/${slug}`,
          method: "PUT",
          body: payload,
        };
      },
    }),
    putFinanceData: builder.mutation({
      query: (data) => {
        let slug = data.slug;
        let id = data.id;
        let payload = {
          partner_stripe_account: {
            confirmed: true,
          },
        };
        return {
          url: `${slug}/finance/${id}`,
          method: "PUT",
          body: payload,
        };
      },
    }),
    postNeedHelpData: builder.mutation({
      query: (data) => {
        let slug = data.slug;
        let payload = {
          url: data.url,
          step: data.step,
          message: data.message.replace(/\n/g, "<br>"),
        };
        return {
          url: `${slug}/need_help`,
          method: "POST",
          body: payload,
        };
      },
    }),
    putLocationData: builder.mutation({
      query: (data) => {
        let slug = data.slug;
        let id = data.id;
        delete data.slug;
        delete data.id;
        let reservation_phone = null;
        let reservation_link = null;
        let priority_reservation_phone_number = null;
        let priority_reservations_url = null;
        data.res_data = data?.res_data?.filter((res) => {
          if (res.type === "reservation_phone") {
            reservation_phone = res.contact.replace(/\D/g, "");
          } else if (res.type === "reservation_link") {
            reservation_link = res.contact;
          } else if (res.type === "priority_reservation_phone") {
            priority_reservation_phone_number = res.contact.replace(/\D/g, "");
          } else if (res.type === "priority_reservations_url") {
            priority_reservations_url = res.contact;
          }
          return res;
        });
        let payload = {
          partner_project_location: {
            name: data.name,
            address_line_one: data.address,
            address_line_two: data.address_2,
            city: data.city,
            state: data.state,
            zip_code: data.zip_code,
            phone: data.phone,
            menu_url: data?.menu_url,
            reservation_phone: reservation_phone,
            reservation_url: reservation_link,
            priority_reservations_url: priority_reservations_url,
            priority_reservation_phone: priority_reservation_phone_number,
            confirmed: data.confirmed,
            onboarding_status: data.onboarding_status,
            partner_brand_id: data.brand_name,
            onboarding_steps_confirmed: JSON.stringify(
              data.onboarding_steps_confirmed,
            ),
          },
        };
        return {
          url: `${slug}/locations/${id}`,
          method: "PUT",
          body: payload,
        };
      },
    }),
    putBrandsData: builder.mutation({
      query: (data) => {
        let slug = data.slug;
        let id = data.id;
        let payload = {
          partner_brand: {
            brand_name: data?.brand_name,
            tag_ids: data?.selected_tags.map((tag) => tag.id),
            favorite_tag_ids: data?.favorite_tags?.map((tag) => tag.id) || [],
            brand_description: data?.description,
            website_url: data?.brand_website,
            gallery_images_ids: data?.gallery_images_ids,
            hero_image_id: data?.hero_image_id,
            logo_dark_id: data?.logo_dark_id,
            logo_light_id: data?.logo_light_id,
            logo_light_wide_id: data?.logo_light_wide_id,
            logo_dark_wide_id: data?.logo_dark_wide_id,
            base_logo_id: data?.base_logo_id,
            modal_background_color: data?.popup_background_color,
            modal_background_image_id: data?.modal_background_image_id,
            modal_button_color: data?.popup_button_color,
            modal_google_webfont: data?.google_font,
            primary_color: data?.background_color,
            no_color_alteration: data?.no_color_alteration,
            secondary_color: data?.button_color,
            confirmed: data.confirmed,
            onboarding_status: data.onboarding_status,
            onboarding_steps_confirmed: JSON.stringify(
              data.onboarding_steps_confirmed,
            ),
          },
        };
        return {
          url: `${slug}/brands/${id}`,
          method: "PUT",
          body: payload,
          formData: true,
        };
      },
    }),
    putFinanceConnection: builder.mutation({
      query: (data) => {
        let id = data.id;
        let slug = data.slug;
        let payload = {
          partner_stripe_account: {
            partner_location_ids: data.checkedLocations,
          },
        };
        return {
          url: `${slug}/finance/${id}.json`,
          method: "PUT",
          body: payload,
          formData: true,
        };
      },
    }),
    putLocationConnection: builder.mutation({
      query: (data) => {
        let id = data.id;
        let slug = data.slug;
        let payload = {
          partner_project_location: {
            partner_stripe_account_id: null,
          },
        };
        return {
          url: `${slug}/locations/${id}`,
          method: "PUT",
          body: payload,
          formData: true,
        };
      },
    }),
    postBrandsImageData: builder.mutation({
      query: (data) => {
        let slug = data.slug;
        let id = data.id;
        delete data.slug;
        delete data.id;
        let payload = {
          partner_brand_image: {
            image: JSON.stringify(data),
          },
        };
        return {
          url: `${slug}/brands/${id}/brand_images`,
          method: "POST",
          body: payload,
          formData: true,
        };
      },
    }),
    postTeamMember: builder.mutation({
      query: (data) => {
        let slug = data.slug;
        delete data.slug;
        delete data.additional_permissions;
        let payload = {
          team_members: data,
        };
        return {
          url: `${slug}/team_members`,
          method: "POST",
          body: payload,
        };
      },
    }),
  }),
});

export const {
  usePutStatusMutation,
  usePutLocationDataMutation,
  usePostResendTeamMemberMutation,
  usePutEditTeamMemberMutation,
  useDeleteTeamMemberMutation,
  usePostCheckSnippetMutation,
  usePutBrandsDataMutation,
  usePutFinanceDataMutation,
  usePostBrandsImageDataMutation,
  usePostStripeLinkMutation,
  useDeleteStripeAccountMutation,
  usePutFinanceConnectionMutation,
  usePutLocationConnectionMutation,
  useLazyGetBrandsDataQuery,
  useLazyGetDashboardDataQuery,
  useLazyGetPermissionsDataQuery,
  usePostTeamMemberMutation,
  usePostLogosDataMutation,
  usePostNeedHelpDataMutation,
  usePutSelectedImagesDataMutation,
} = partnerApi;
